<template>
  <v-app>
     <router-view/>
    <v-footer app><v-spacer/><div class="text-caption">version {{version}}</div><v-spacer/></v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
            version: process.env.VUE_APP_VERSION
    }
  },
}
</script>
